export const setFirstPage = (search) => {
  let searchStr = "?";

  search && Object.keys(search).forEach(key => {
    let value = search[key];
    if (key === "page") value = 1;
    if (key) {
      searchStr += `${key}=${value}&`;
    }
  });
  return searchStr;
};