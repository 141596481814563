import { Map, fromJS } from 'immutable';


const abilitiesReducer = (state = Map(), action) => {
  let tempState = state;
  switch (action.type) {
  case 'LOADING_STARTED':
    return tempState.set('loading', true);

  case 'FETCH_ABILITIES':
    action.payload.data.results.map((ability) => {
      tempState = tempState.setIn([ 'items', ability.summary_code ], fromJS(ability))
        .set('loading', false);
      return null;
    });
    return tempState.setIn([ "currentPage", "limit" ], action.payload.limit)
      .setIn([ "currentPage", "offset" ], action.payload.offset)
      .setIn([ "currentPage", "count" ], action.payload.data.count)
      .setIn([ "currentPage", "ids" ], fromJS(action.payload.data.results.map((ability) => { return (ability.summary_code); })))
      .setIn([ "currentPage", "prev" ], action.payload.data.previous)
      .setIn([ "currentPage", "next" ], action.payload.data.next)
      .setIn([ "facets", "stage" ], action.payload.data.facets.stage)
      .setIn([ "facets", "school" ], action.payload.data.facets.school)
      .setIn([ "facets", "category" ], action.payload.data.facets.category)
      .setIn([ "facets", "subject" ], action.payload.data.facets.subject)
      .setIn([ "facets", "profession" ], action.payload.data.facets.profession)
      .setIn([ "facets", "scope" ], action.payload.data.facets.scope)
      .setIn([ "facets", "version" ], action.payload.data.facets.version)
      .set('loading', false);

  case 'FETCH_SUMMARY':
    tempState = tempState.setIn([ 'items', action.payload.summary_code ], fromJS(action.payload))
      .set('loading', false);
    return tempState;

  case 'FETCH_CHILDREN':
    action.payload.data.results.map(child => {
      tempState = tempState.setIn([ 'children', child.summary_code ], fromJS(child))
        .set('loading', false);
      return null;
    });
    return tempState.setIn([ "currentChildPage", "limit" ], action.payload.limit)
      .setIn([ "currentChildPage", "offset" ], action.payload.offset)
      .setIn([ "currentChildPage", "count" ], action.payload.data.count)
      .setIn([ "currentChildPage", "ids" ], fromJS(action.payload.data.results.map((child) => { return (child.summary_code); })))
      .setIn([ "currentChildPage", "prev" ], action.payload.data.previous)
      .setIn([ "currentChildPage", "next" ], action.payload.data.next)
      .set('loading', false);

  case 'FETCH_CHANGES':
    action.payload.data.results.map(change => {
      tempState = tempState.setIn([ 'changes', change.code ], fromJS(change))
        .set('loading', false);
      return null;
    });
    return tempState.setIn([ "currentChangesPage", "limit" ], action.payload.limit)
      .setIn([ "currentChangesPage", "offset" ], action.payload.offset)
      .setIn([ "currentChangesPage", "count" ], action.payload.data.count)
      .setIn([ "currentChangesPage", "ids" ], fromJS(action.payload.data.results.map((change) => { return (change.code); })))
      .setIn([ "currentChangesPage", "prev" ], action.payload.data.previous)
      .setIn([ "currentChangesPage", "next" ], action.payload.data.next)
      .set('loading', false);

  default:
    return state;
  }
};

export default abilitiesReducer;