import queryString from 'query-string';
import axios from '@axios';

const getMode = (pathname) => {
  const fixedPathname = pathname ? pathname : "/";
  if (fixedPathname === '/' || fixedPathname.includes('/abilities/') || fixedPathname.includes('/ability/')) {
    return "abilities";
  }
  else if (fixedPathname.includes('/objectives/') || fixedPathname.includes('/objective/')) {
    return "objectives";
  }
};

export const loadingStarted = () => dispatch => {
  dispatch({ type: 'LOADING_STARTED' });
};

export const fetchAbilities = (pathname, query, limit = 10, offset = 0) => async dispatch => {
  query = query ? query : "?";
  const validArray = [ "stage", "school", "category", "subject", "profession", "scope", "version", "query" ];
  const pageParam = queryString.parse(query);

  for (var key in pageParam) {

    if (!validArray.includes(key)) delete pageParam[`${key}`];
  }
  query = "?" + queryString.stringify(pageParam);

  const mode = getMode(pathname);
  const response = await axios.get(`/${mode}/${query}&limit=${limit}&offset=${offset}&facets=true&serializer=nested&root=true`);
  dispatch({ type: 'FETCH_ABILITIES', payload: { data: response.data, limit: limit, offset: offset } });
};

export const fetchSummary = (pathname, summaryCode) => async dispatch => {
  const mode = getMode(pathname);
  const response = await axios.get(`/${mode}/${summaryCode}/?serializer=nested`);
  dispatch({ type: 'FETCH_SUMMARY', payload: response.data });
};

export const fetchChildren = (pathname, summaryCode, limit = 10, offset = 0) => async dispatch => {
  const mode = getMode(pathname);
  const response = await axios.get(`/${mode}/${summaryCode}/children/?limit=${limit}&offset=${offset}&serializer=nested`);
  dispatch({ type: 'FETCH_CHILDREN', payload: { data: response.data, limit: limit, offset: offset } });
};

export const fetchChanges = (pathname, summaryCode, limit = 10, offset = 0) => async dispatch => {
  const mode = getMode(pathname);
  const response = await axios.get(`/${mode}/${summaryCode}/changes/?serializer=nested`);
  dispatch({ type: 'FETCH_CHANGES', payload: { data: response.data, limit: limit, offset: offset } });
};
