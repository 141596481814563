import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import FacetsList from './FacetsList.jsx';
import QueryRow from './QueryRow.jsx';
import Pager from './Pager.jsx';
import LinkableTable from './LinkableTable.jsx';
import Breadcrumbs from './Breadcrumbs.jsx';


class MainPage extends React.Component {
  render() {
    let pageParam = queryString.parse(this.props.location.search);
    let querystring = this.props.location.search;
    let pathname = this.props.location.pathname;

    let limitQuery = 10;

    if (!pageParam.page || pageParam.page <= 0) { pageParam.page = 1; };

    let mode = null;
    if (pathname === '/')
      mode = "Umiejętności";
    if (pathname === '/objectives/')
      mode = "Cele kształcenia";

    return (
      <div>
        <Breadcrumbs />
        <FacetsList queryString={querystring} />
        <QueryRow queryString={querystring} />
        <h1>{mode}</h1>
        <LinkableTable pathName={pathname} queryString={querystring} offset={(pageParam.page - 1) * limitQuery} limit={limitQuery} query={pageParam.q} />
        <Pager pathName={pathname} currPage={pageParam.page - 1} limit={limitQuery} query={querystring} />
      </div>
    );
  }
};

MainPage.propTypes = {
  location: PropTypes.object //HOC
};

export default MainPage;
