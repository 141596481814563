import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadingStarted } from '../actions';
import { fetchChanges } from '../actions';
import { renderSpinner } from '../components/RenderSpinner';

class ChangeRegister extends React.Component {

  componentDidMount() {
    this.props.loadingStarted();
    this.props.fetchChanges(this.props.pathName, this.props.summaryCode);
  }

  renderChangesList() {
    const changesList = this.props.changes.getIn([ "currentChangesPage", "ids" ]);
    const detailsChange = this.props.changes.getIn([ "changes" ]);
    return changesList && changesList.map((change) => {
      return (
        <tr key={change}>
          <td>{detailsChange.getIn([ change, "change" ])}</td>
          <td>Nowa umiejętność</td>
          <td>{detailsChange.getIn([ change, "date" ])}</td>
          <td>{change}</td>
        </tr>
      );
    });
  }

  render() {
    let loadingState = this.props.changes.getIn([ "loading" ]);
    return (
      <div className="spinnerParent">
        <table className="table">
          <thead>
            <tr>
              <th>Symbol zmiany</th>
              <th>Rodzaj zmiany</th>
              <th>Data</th>
              <th>Kod</th>
            </tr>
          </thead>
          <tbody>
            {this.renderChangesList()}
          </tbody>
        </table>
        {loadingState && renderSpinner()}
      </div>
    );
  }
}

ChangeRegister.propTypes = {
  sendRequest: PropTypes.func, //HOC
  changes: PropTypes.object, //HOC
  fetchChanges: PropTypes.func, //ACTION
  loadingStarted: PropTypes.func, //ACTION
  pathName: PropTypes.string,
  summaryCode: PropTypes.string,
};

const mapStateToProps = (state) => {
  return { changes: state.abilities };
};

export default connect(mapStateToProps, { fetchChanges, loadingStarted })(ChangeRegister);