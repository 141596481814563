import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import Callback from './callback';
import LogoutCallback from './logoutCallback';
import { loginCallbackPath, logoutCallbackPath } from '../utils/authUtils';
import NotFound from '../components/notFound';
import Loading from '../components/loading';

import Header from '../../uspp-ui/containers/Header';
import AboutService from '../../uspp-ui/containers/AboutService';
import LegalActs from '../../uspp-ui/containers/LegalActs';
import AbilityDetailsPage from '../../uspp-ui/containers/AbilityDetailsPage';
import MainPage from '../../uspp-ui/containers/MainPage';
import TopBar from '../../uspp-ui/containers/Topbar';
import Footer from '../../uspp-ui/containers/Footer';
import '../../uspp-ui/styles/index.scss';


@connect(
  state => ({
    loginStore: state.login.get('loginData'),
    loginFinishingInProgress: state.login.get('login_finishing_in_progress'),
  })
)
export default class Root extends Component {
  renderContent = () => {

    if (this.props.loginFinishingInProgress) {
      //login finishing is in progress
      return <Loading className="text-center mt-5" />;
    }

    return (
      <Switch>
        <Route path="/" exact component={MainPage} />
        <Route path="/objectives/" exact component={MainPage} />
        <Route path="/about" exact component={AboutService} />
        <Route path="/legal_acts" exact component={LegalActs} />
        <Route path={`/ability/:sumCode`} component={AbilityDetailsPage} />
        <Route path={`/objective/:sumCode`} component={AbilityDetailsPage} />
        <Route path={ loginCallbackPath } exact component={Callback} />
        <Route path={ logoutCallbackPath } exact component={LogoutCallback} />
        <Route component={NotFound} />
      </Switch>
    );
  };

  render() {
    return (
      <div>
        <TopBar />
        <Container className="mb-2">
          <Header />
          <div className="main-content">
            {this.renderContent()}
          </div>
          <Footer />
        </Container>
      </div>
    );
  }
}

Root.propTypes = {
  loginStore: PropTypes.object, //HOC
  loginFinishingInProgress: PropTypes.bool, //HOC
};
