import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { addToQuery } from '../utils/queryUtils.js';
import Chevron01 from '@assets/img/icons/chevron-01.png';
import Chevron02 from '@assets/img/icons/chevron-02.png';

class Pager extends React.Component {
  render() {
    const abilitiesProps = this.props.abilities;
    let isFirstPage = false, isLastPage = false;
    let btnPrevPrev, btnPrev, btnNextNext, btnNext;
    const count = (abilitiesProps && abilitiesProps.getIn([ "currentPage", "count" ])) || 0;
    const lastPage = Math.ceil(count / this.props.limit);

    if (this.props.currPage <= 0) isFirstPage = true;
    if (this.props.currPage >= (lastPage - 1)) isLastPage = true;

    btnPrevPrev = `${addToQuery(this.props.query, "page", "1")}`;
    btnPrev = `${addToQuery(this.props.query, "page", (this.props.currPage + 1) - 1)}`;
    btnNextNext = `${addToQuery(this.props.query, "page", lastPage)}`;
    btnNext = `${addToQuery(this.props.query, "page", (this.props.currPage + 1) + 1)}`;

    let mode = null;
    if (!this.props.pathName.includes('/objectives/'))
      mode = "/";
    if (this.props.pathName.includes('/objectives/'))
      mode = "/objectives/";

    return (
      <div className="row">
        <div className="col-sm-12 col-lg-offset-3">
          <div className="d-flex justify-content-center">
            <ul className="pager list-inline">
              <li className={`${isFirstPage ? "disabled" : ""} previous list-inline-item`}>
                <Link to={mode + btnPrevPrev}>
                  <img alt="arrows back" src={Chevron01} />
                </Link></li>
              <li className={`${isFirstPage ? "disabled" : ""} previous list-inline-item`}>
                <Link to={mode + btnPrev}>
                  <img alt="arrow back" src={Chevron02} />
                </Link></li>

              <li className="list-inline-item"><span>Znaleziono: {count} obiektów, </span></li>
              <li className="list-inline-item"><span>Strona: {`${(this.props.currPage + 1)}/${lastPage ? lastPage : "?"}`}</span></li>

              <li className={`${isLastPage ? "disabled" : ""} next list-inline-item`}>
                <Link to={!isLastPage ? mode + btnNext : mode + btnNextNext}>
                  <img alt="arrow forward" src={Chevron02} />
                </Link></li>
              <li className={`${isLastPage ? "disabled" : ""} next list-inline-item`}>
                <Link to={mode + btnNextNext}>
                  <img alt="arrows forward" src={Chevron01} />
                </Link></li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
};

Pager.propTypes = {
  abilities: PropTypes.object, //HOC
  currPage: PropTypes.number,
  limit: PropTypes.number,
  pathName: PropTypes.string,
  query: PropTypes.string
};

const mapStateToProps = (state) => {
  return { abilities: state.abilities };
};

export default connect(mapStateToProps)(Pager);