import React from 'react';

import { __env } from '../../envloader/index';
import Breadcrumbs from './Breadcrumbs.jsx';

const AboutService = () => {
  return (
    <div>
      <Breadcrumbs />
      <div className="text-justify">
        <p>Usługa Słownika Podstawy Programowej (USPP) jest częścią otwartej na współpracę z instytucjami publicznymi, samorządowymi i rynkiem wydawniczym 
          inicjatywy Poznańskiego Centrum Superkomputerwo-Sieciowego (PCSS) mającej na celu wsparcie otwartych standardów edukacyjnych oraz procesów 
          nauczania opartych o dostępne w sieci Internet zasoby szeroko rozumianych materiałów edukacyjnych. W efekcie podjętych już prac rozwojowych 
          planujemy udostępnić nauczycielom, uczniom i entuzjastom nauczania zestawu różnych narzędzi wspomagających dydaktykę z wykorzystaniem technologii 
          informacyjno-komunikacyjnych (TIK).</p>

        <p>W ramach pilotażu <a href={__env.CLASSROOM_URL}>PIONIER Research & Classroom</a> 2019-2020 usługa została udostępniona i zintegrowana z innymi 
        usługami edukacyjnymi, które oferowane są przez PCSS oraz Konsorcjum PIONIER Polski Internet Optyczny.</p>

        <p>Usługa Słownika Podstawy Programowej:</p>
        <ul>
          <li>standaryzuje formę oznaczania materiałów dydaktycznych zgodnie z umiejętnościami i celami kształcenia zdefiniowanymi w rozporządzeniach 
            Ministerstwa Edukacji Narodowej w sprawie podstawy programowej,</li>
          <li>umożliwią przeszukiwanie i filtrowanie treści rozporządzeń Ministerstwa Edukacji Narodowej w sprawie podstawy programowej według fraz 
            tekstowych, etapów edukacji, rodzai szkół, przedmiotów, etc.,</li>
          <li>udostępnia interfejs programistyczny (API) umożliwiający integrację USPP z innymi usługami edukacyjnymi.</li>
        </ul>

        <p>Aktualnie zestaw danych obejmuje Rozporządzenia Ministra Edukacji Narodowej z dnia 14 lutego 2017 roku oraz 31 marca 2017 roku i pokrywa 
          następujące etapy edukacji:</p>
        <ul>
          <li>etap wychowania przedszkolnego,</li>
          <li>szkoły podstawowej (w tym dla uczniów z niepełnosprawnością intelektualną w stopniu umiarkowanym lub znacznym),</li>
          <li>kształcenia ogólnego dla branżowej szkoły I stopnia,</li>
          <li>kształcenia ogólnego dla szkoły specjalnej przysposabiającej do pracy,</li>
          <li>kształcenia ogólnego dla szkoły policealnej,</li>
          <li>kształcenia w zawodach na poziomie szkół ponadpodstawowych (pięcioletniego technikum, trzyletniej branżowej szkoły I stopnia, dwuletniej 
            branżowej szkoły II stopnia),</li>
          <li>szkoły policealnej,</li>
          <li>czteroletniego technikum.</li>
        </ul>

        <p>Rozwój i utrzymanie Usługi Słownika Podstawy Programowej finansowane jest z opłat wnoszonych przez uczestników {<strong>Porozumienia na rzecz 
          Rozwoju Otwartego Standardu Słownika Podstawy Programowej</strong>}, którzy mają możliwość komercyjnego korzystania z usługi i wpływ na kierunek
           jej rozwoju.</p>

        <p>Zabronione jest pobieranie i/lub wykorzystywanie zawartości bazy danych bez umowy i pisemnej zgody PCSS. Dane zgromadzone i prezentowane w Usłudze
           Słownika Podstawy programowej podlegają ochronie prawnej zgodnie z Ustawa z dnia 27 lipca 2001 r. o ochronie baz danych.</p>

        <p>W celu uzyskania dalszych informacji o zasadach i korzyściach wynikających z przystąpienia do Porozumienia proszę wysłać mail na adres:
          {/* <script>
                        document.write('<'+'a'+' '+'h'+'r'+'e'+'f'+'='+"'"+'&'+'#'+'1'+'0'+'9'+';'+'a'+'&'+'#'+'1'+'0'+'5'+';'+'l'+'t'+'o'+
                            '&'+'#'+'5'+'8'+';'+'p'+'i'+'&'+'#'+'1'+'1'+'1'+';'+'n'+'t'+'e'+'k'+'&'+'#'+'6'+'4'+';'+'&'+'#'+'1'+
                            '0'+'9'+';'+'a'+'%'+'6'+'E'+'&'+'#'+'4'+'6'+';'+'%'+'7'+'0'+'o'+'&'+'#'+'1'+'2'+'2'+';'+'n'+'%'+'6'+
                            '1'+'&'+'#'+'3'+'7'+';'+'6'+'E'+'%'+'&'+'#'+'5'+'0'+';'+'E'+'p'+'l'+"'"+'>'+'p'+'i'+'o'+'n'+'&'+'#'+
                            '1'+'1'+'6'+';'+'e'+'k'+'&'+'#'+'6'+'4'+';'+'m'+'a'+'n'+'&'+'#'+'4'+'6'+';'+'p'+'o'+'z'+'&'+'#'+'1'+
                            '1'+'0'+';'+'a'+'n'+'&'+'#'+'4'+'6'+';'+'p'+'l'+'<'+'/'+'a'+'>');
                    </script> */}
          <a href="mailto:piontek@ma%6E.%70ozn%61%6E%2Epl">piontek@man.poznan.pl</a>
          <noscript>[włącz JavaScript aby zobaczyć adres email]</noscript>.
        </p>
      </div>
    </div>
  );
};

export default AboutService;