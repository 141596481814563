import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';

class Breadcrumbs extends React.Component {

  returnBreadcrumbItems() {
    const searchString = this.props.location.search;
    const parseUrl = queryString.parse(searchString);

    if (parseUrl.query) delete parseUrl.query;

    let labelStr = "non-name";
    
    let searchArr = searchString.split("&");
    let categoryArray = [ "stage", "school", "category", "subject", "profession", "scope", "version", "parent", "children" ];

    let pathname = this.props.location.pathname;
    let abilityCode = pathname.split("/");

    searchArr.pop();
    if (searchArr[0]) searchArr[0] = searchArr[0].slice(1);

    let currUrl = "";
    let mode = !pathname.includes("/objectives/") ? "" : "objectives/";
    let mainLink;
    
    let facetBreadcrumbs = categoryArray && categoryArray.map((facet) => {
      if (this.props.abilities && this.props.abilities.getIn([ "facets" ])) {
        let facetsArr = this.props.abilities.getIn([ "facets", facet ]);
        if (facet in parseUrl) {
          currUrl += facet + "=" + parseUrl[facet] + "&";
          let filteredFacet = facetsArr.filter((item) => {
            return item.code === parseUrl[facet];
          });
          labelStr = filteredFacet[0] && filteredFacet[0].name;
          mainLink = `/${mode}?${currUrl}`;
          return (
            <BreadcrumbItem key={facet}><Link to={mainLink}>{labelStr}</Link></BreadcrumbItem>
          );
        }
        return null;
      }
      return null;
    });


    let itemsList = this.props.abilities.getIn([ "items" ]);
    let parent = null;
    itemsList && itemsList.map((item) => {
      if (item.getIn([ "summary_code" ]) === abilityCode[abilityCode.length - 2]) {
        parent = item.getIn([ "parent", "summary_code" ]) && item.getIn([ "parent", "summary_code" ]);
      }
      return null;
    });

    if (!mainLink) mainLink = `/${mode}?${currUrl}`;
    let linkToParent;

    let idBreadcrumbs = null;
    if (abilityCode[abilityCode.length - 2] && abilityCode.length >= 4) {
      linkToParent = "/" + abilityCode[1] + "/" + abilityCode[abilityCode.length - 2] + "/?" + currUrl;
      idBreadcrumbs = 
      <BreadcrumbItem key={abilityCode[abilityCode.length - 2]}>
        <Link to={linkToParent}>{abilityCode[abilityCode.length - 2]}
        </Link>
      </BreadcrumbItem>;
    }
    else if (abilityCode[abilityCode.length - 1]) {
      idBreadcrumbs = 
      <BreadcrumbItem key={abilityCode[abilityCode.length - 1]}>
        <Link to={mainLink}>{abilityCode[abilityCode.length - 1]}
        </Link>
      </BreadcrumbItem>;
    }

    if (idBreadcrumbs && (parent === null || parent === undefined)) {
      facetBreadcrumbs[7] = idBreadcrumbs;
    }

    if (idBreadcrumbs && parent) {
      linkToParent = "/" + abilityCode[1] + "/" + parent + "/?" + currUrl;
      facetBreadcrumbs[7] = <BreadcrumbItem key={parent}><Link to={linkToParent}>{parent}</Link></BreadcrumbItem>;
      facetBreadcrumbs[8] = idBreadcrumbs;
    }

    return (
      facetBreadcrumbs
    );
  };

  render() {
    let pathname = this.props.location.pathname;
    let abilityCode = pathname.split("/");
    return (
      <Breadcrumb tag="nav" listTag="div">
        {abilityCode.length === 2 ?
          <BreadcrumbItem><Link to="/">Umiejętności</Link></BreadcrumbItem> :
          (abilityCode.length === 3 && pathname.includes("objectives") ?
            <BreadcrumbItem><Link to="/objectives/">Cele kształcenia</Link></BreadcrumbItem> :
            <BreadcrumbItem><Link to="/">Strona Główna</Link></BreadcrumbItem>)}

        {abilityCode.length === 3 && !pathname.includes("objectives") ?
          (pathname.includes("about") ? <BreadcrumbItem><Link to={`/${abilityCode[abilityCode.length - 2]}/`}>O Usłudze</Link></BreadcrumbItem> :
            (pathname.includes("legal_acts") ? <BreadcrumbItem><Link to={`/${abilityCode[abilityCode.length - 2]}/`}>Akty prawne</Link></BreadcrumbItem> :
              null)) : null}

        {this.returnBreadcrumbItems()}
      </Breadcrumb>
    );
  }
};

Breadcrumbs.propTypes = {
  location: PropTypes.object, //HOC
  abilities: PropTypes.object, //HOC
};

const mapStateToProps = (state) => {
  return { abilities: state.abilities };
};

export default connect(mapStateToProps)(withRouter(Breadcrumbs));