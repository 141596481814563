import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, Input, InputGroup, Form } from 'reactstrap';
import { FaSearch } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';

import { addToQuery } from '../utils/queryUtils.js';

class QueryRow extends Component {

  constructor(props) {
    super(props);
    this.state = { value: '' };
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  eraseInput = () => {
    this.handleChange({ target: { value: '' } });
  };

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.props.history.push(this.props.location.pathname + addToQuery(this.props.queryString, "query", this.state.value));
  }

  render() {
    return (
      <div className="row query">
        <div className="col-sm-4">
          <Form onSubmit={this.onFormSubmit}>
            <InputGroup>
              <Input 
                type="text" 
                className="form-control" 
                placeholder={'Szukaj...'} 
                id="query" 
                name="query" 
                value={this.state.value} 
                onChange={this.handleChange} 
              />
              <span className="input-group-btn">
                <Button type="submit" className="btn btn-primary"><FaSearch /></Button>
              </span>
            </InputGroup>
          </Form>
        </div>
      </div>
    );
  }
};

QueryRow.propTypes = {
  history: PropTypes.object, //HOC
  location: PropTypes.object, //HOC
  queryString: PropTypes.string,
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(withTranslation()(withRouter(QueryRow)));
