import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { fetchAbilities } from '../actions';
import { loadingStarted } from '../actions';
import { setFirstPage } from '../utils/setFirstPage';
import { detailsStrings } from '../utils/detailsStrings';
import { renderSpinner } from '../components/RenderSpinner';

class LinkableTable extends Component {

  componentDidMount() {
    this.props.loadingStarted();
    this.props.fetchAbilities(this.props.pathName, this.props.queryString, this.props.limit, this.props.offset);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.limit !== this.props.limit || prevProps.offset !== this.props.offset || prevProps.queryString !== this.props.queryString) {
      this.props.loadingStarted();
      this.props.fetchAbilities(this.props.pathName, this.props.queryString, this.props.limit, this.props.offset);
    }
  }

  renderList() {
    const abilitieArr = this.props.abilities.getIn([ "currentPage", "ids" ]);
    const detailsAb = this.props.abilities.getIn([ "items" ]);
    
    let mode = null;
    if (this.props.pathName === '/')
      mode = "ability";
    if (this.props.pathName === '/objectives/')
      mode = "objective";

    let search = queryString.parse(this.props.queryString);
    if (abilitieArr && abilitieArr.size === 0) {
      return (
        <tr className="linkableItem">
          <td className="col-code"></td>
          <td className="col-desc">Brak wyników</td>
        </tr>
      );
    }
    else {
      return abilitieArr && abilitieArr.map((ability) => {
        return (
          <tr className="linkableItem" key={ability}>
            <td className="col-code"><Link to={`/${mode}/${ability}/${setFirstPage(search)}`}>{detailsAb.getIn([ ability, "summary_code" ])}</Link></td>
            <td className="col-desc"><Link to={`/${mode}/${ability}/${setFirstPage(search)}`}>{detailsAb.getIn([ ability, "name" ])}</Link><br />
              <small>
                <i>Poziom: </i>
                {detailsStrings("stages", detailsAb, ability)} <br />
                <i>Szkoła: </i>
                {detailsStrings("schools", detailsAb, ability)} <br />
                <i>Zakres: </i>
                {detailsStrings("scope", detailsAb, ability)} <br />
                <i>Wersje: </i>
                {detailsStrings("versions", detailsAb, ability)} <br />
              </small>
            </td>
          </tr>
        );
      });
    }
    
  };

  render() {
    let loadingState = this.props.abilities.getIn([ "loading" ]);
    return (
      <div className="spinnerParent">
        {loadingState && renderSpinner()}
        <table className="table table-hover table-borderless">
          <thead>
            <tr>
              <th className="col-code">Odnośnik</th>
              <th className="col-desc">Nazwa</th>
            </tr>
          </thead>
          <tbody>
            {this.renderList()}
          </tbody>
        </table>
      </div>
    );
  }
};

LinkableTable.propTypes = {
  abilities: PropTypes.object, //HOC
  fetchAbilities: PropTypes.func, //HOC
  loadingStarted: PropTypes.func, //HOC
  pathName: PropTypes.string,
  queryString: PropTypes.string,
  limit: PropTypes.number,
  offset: PropTypes.number,
};

const mapStateToProps = (state) => {
  return { abilities: state.abilities };
};

export default connect(mapStateToProps, { fetchAbilities, loadingStarted })(LinkableTable);