import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MathJax from 'react-mathjax-preview';

import { fetchSummary } from '../actions';
import { loadingStarted } from '../actions';
import Breadcrumbs from './Breadcrumbs.jsx';
import { detailsStrings } from '../utils/detailsStrings';
import { renderSpinner } from '../components/RenderSpinner';

class AbilityDetails extends React.Component {

  componentDidMount() {
    if (this.props.summary === undefined) {
      this.props.loadingStarted();
      this.props.fetchSummary(this.props.pathName, this.props.summaryCode);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.summary === undefined) {
      this.props.loadingStarted();
      this.props.fetchSummary(this.props.pathName, this.props.summaryCode);
    }
  }

  render() {
    let pathname = this.props.pathName;
    let loadingState = this.props.abilities.getIn([ "loading" ]);

    const { summary } = this.props;
    if (!summary) {
      return null;
    }
    const summaryName = summary.getIn([ "name" ]);
    return (
      <div className="spinnerParent">
        <Breadcrumbs />
        {pathname.includes('/ability/') ? 
          <h1>Szczegóły bieżącej umiejętności</h1> : 
          (pathname.includes('/objective/') ? 
            <h1>Szczegóły bieżącego celu kształcenia</h1> : 
            null
          )}
        {loadingState && renderSpinner() }
        <table className="table">
          <tbody>
            <tr>
              <td className="col-legend">Odnośnik:</td>
              <td>{summary.getIn([ "summary_code" ])}</td>
            </tr>
            <tr>
              <td className="col-legend">Nazwa:</td>
              <td><MathJax math={summaryName} /></td>
            </tr>
            <tr>
              <td className="col-legend">Poziom:</td>
              <td>
                {detailsStrings("stages", summary)}<br />
              </td>
            </tr>
            <tr>
              <td className="col-legend">Szkoła:</td>
              <td>
                {detailsStrings("schools", summary)}
              </td>
            </tr>

            <tr>
              <td className="col-legend">Przedmiot:</td>
              <td>{detailsStrings("subject", summary)}</td>
            </tr>

            <tr>
              <td className="col-legend">Zakres:</td>
              <td>{detailsStrings("scope", summary)}</td>
            </tr>

            <tr>
              <td className="col-legend">Wersja:</td>
              <td>
                {detailsStrings("versions", summary)}<br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
}

AbilityDetails.propTypes = {
  summary: PropTypes.object, //HOC
  fetchSummary: PropTypes.func, //HOC
  loadingStarted: PropTypes.func, //HOC
  abilities: PropTypes.func, //HOC
  sendRequest: PropTypes.func, //HOC
  pathName: PropTypes.string,
  summaryCode: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  return { summary: state.abilities.getIn([ "items", ownProps.summaryCode ]), abilities: state.abilities };
};

export default connect(mapStateToProps, { fetchSummary, loadingStarted })(AbilityDetails);
