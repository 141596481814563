import React from 'react';

import Breadcrumbs from './Breadcrumbs.jsx';
import du2019_991 from '@assets/img/du2019.991.png';
import du2018_1679 from '@assets/img/du2018.1679.png';
import du2018_744 from '@assets/img/du2018.744.png';
import du2018_467 from '@assets/img/du2018.467.png';
import du2017_860 from '@assets/img/du2017.860.png';
import du2017_356 from '@assets/img/du2017.356.png';

const LegalActs = () => {
  return (
    <div>
      <Breadcrumbs />
      <div>
        <h2 className="legal-title">Usługa indeksuje dane zgodnie z następującymi rozporządzeniami:</h2>

        <div className="media">
          <div className="media-left">
            <a href="http://dziennikustaw.gov.pl/DU/2019/991">
              <img className="media-object img-thumbnail" width="120px" src={du2019_991} alt="thumbnail" />
            </a>
          </div>
          <div className="media-body">
            <h4 className="media-heading"><a href="http://dziennikustaw.gov.pl/DU/2019/991">Rozporządzenie Ministra Edukacji Narodowej z dnia 16 maja 2019 r.
              w sprawie podstaw programowych kształcenia w zawodach szkolnictwa branżowego oraz dodatkowych umiejętności zawodowych
              w zakresie wybranych zawodów szkolnictwa branżowego</a></h4>

            Opublikowane 28 maja 2019 r. w Dzienniku Ustaw 2019 poz. 991
          </div>
        </div>

        <div className="media">
          <div className="media-left">
            <a href="http://dziennikustaw.gov.pl/DU/2018/1679">
              <img className="media-object img-thumbnail" width="120px" src={du2018_1679} alt="thumbnail" />
            </a>
          </div>
          <div className="media-body">
            <h4 className="media-heading">
              <a href="http://dziennikustaw.gov.pl/DU/2018/1679">
              Rozporządzenie Ministra Edukacji Narodowej z dnia 26 lipca 2018 r. zmieniające rozporządzenie w sprawie podstawy programowej wychowania 
              przedszkolnego oraz podstawy programowej kształcenia ogólnego dla szkoły podstawowej, w tym dla uczniów z niepełnosprawnością intelektualną 
              w stopniu umiarkowanym lub znacznym, kształcenia ogólnego dla branżowej szkoły I stopnia, kształcenia ogólnego dla szkoły specjalnej 
              przysposabiającej do pracy oraz kształcenia ogólnego dla szkoły policealnej
              </a>
            </h4>

            Opublikowane 26 lipca 2018 r. w Dzienniku Ustaw 2018 poz. 1679
          </div>
        </div>

        <div className="media">
          <div className="media-left">
            <a href="http://dziennikustaw.gov.pl/DU/2018/744">
              <img className="media-object img-thumbnail" width="120px" src={du2018_744} alt="thumbnail" />
            </a>
          </div>
          <div className="media-body">
            <h4 className="media-heading">
              <a href="http://dziennikustaw.gov.pl/DU/2018/744">
              Rozporządzenie Ministra Edukacji Narodowej z dnia 5 kwietnia 2018 r. zmieniające rozporządzenie w sprawie podstawy programowej kształcenia
               w zawodach
              </a>
            </h4>

            Opublikowane 17 kwietnia 2018 r. w Dzienniku Ustaw 2018 poz. 744
          </div>
        </div>

        <div className="media">
          <div className="media-left">
            <a href="http://dziennikustaw.gov.pl/DU/2018/467">
              <img className="media-object img-thumbnail" width="120px" src={du2018_467} alt="thumbnail" />
            </a>
          </div>
          <div className="media-body">
            <h4 className="media-heading">
              <a href="http://dziennikustaw.gov.pl/DU/2018/467">
                Rozporządzenie Ministra Edukacji Narodowej z dnia 30 stycznia 2018 r. w sprawie podstawy programowej kształcenia ogólnego dla liceum 
                ogólnokształcącego, technikum oraz branżowej szkoły II stopnia
              </a>
            </h4>

            Opublikowane 2 marca 2018 r. w Dzienniku Ustaw 2018 poz. 467
          </div>
        </div>

        <div className="media">
          <div className="media-left">
            <a href="http://dziennikustaw.gov.pl/DU/2017/860">
              <img className="media-object img-thumbnail" width="120px" src={du2017_860} alt="thumbnail" />
            </a>
          </div>
          <div className="media-body">
            <h4 className="media-heading">
              <a href="http://dziennikustaw.gov.pl/DU/2017/860">
                Rozporządzenie Ministra Edukacji Narodowej z dnia 31 marca 2017 r. w sprawie podstawy programowej kształcenia w zawodach
              </a>
            </h4>

            Opublikowane 28 kwietnia 2017 r. w Dzienniku Ustaw 2017 poz. 860
          </div>
        </div>

        <div className="media">
          <div className="media-left">
            <a href="http://dziennikustaw.gov.pl/DU/2017/356">
              <img className="media-object img-thumbnail" width="120px" src={du2017_356} alt="thumbnail" />
            </a>
          </div>
          <div className="media-body">
            <h4 className="media-heading">
              <a href="http://dziennikustaw.gov.pl/DU/2017/356">
                Rozporządzenie Ministra Edukacji Narodowej z dnia 14 lutego 2017 r. w sprawie podstawy programowej wychowania przedszkolnego
                 oraz podstawy programowej kształcenia ogólnego dla szkoły podstawowej, w tym dla uczniów z niepełnosprawnością intelektualną 
                 w stopniu umiarkowanym lub znacznym, kształcenia ogólnego dla branżowej szkoły I stopnia, kształcenia ogólnego dla szkoły 
                 specjalnej przysposabiającej do pracy oraz kształcenia ogólnego dla szkoły policealnej
              </a>
            </h4>

            Opublikowane 24 lutego 2017 r. w Dzienniku Ustaw 2017 poz. 356
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalActs;