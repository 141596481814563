import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { addToQuery } from '../utils/queryUtils.js';
import Chevron01 from '@assets/img/icons/chevron-01.png';
import Chevron02 from '@assets/img/icons/chevron-02.png';

class PagerChildren extends React.Component {
  render() {
    const abilitiesProps = this.props.children;
    let isFirstPage = false, isLastPage = false;
    let btnPrevPrev, btnPrev, btnNextNext, btnNext;
    const count = abilitiesProps.getIn([ "currentChildPage", "count" ]);
    const lastPage = Math.ceil(count / this.props.limit);

    if (this.props.currPage <= 0) isFirstPage = true;
    if (this.props.currPage >= (lastPage - 1)) isLastPage = true;

    btnPrevPrev = `/${addToQuery(this.props.query, "page", "1")}`;
    btnPrev = `/${addToQuery(this.props.query, "page", (this.props.currPage + 1) - 1)}`;
    btnNextNext = `/${addToQuery(this.props.query, "page", lastPage)}`;
    btnNext = `/${addToQuery(this.props.query, "page", (this.props.currPage + 1) + 1)}`;

    let mode = null;
    if (this.props.pathname.includes('/ability/'))
      mode = "ability";
    if (this.props.pathname.includes('/objective/'))
      mode = "objective";

    return (
      <div className="row">
        <div className="col-sm-12 col-lg-offset-3">
          <div className="d-flex justify-content-center">
            <ul className="pager list-inline">
              <li className={`${isFirstPage ? "disabled" : ""} previous list-inline-item`}>
                <Link to={`/${mode}/${this.props.summaryCode}${btnPrevPrev}`}>
                  <img alt="arrows back" src={Chevron01} />
                </Link></li>
              <li className={`${isFirstPage ? "disabled" : ""} previous list-inline-item`}>
                <Link to={`/${mode}/${this.props.summaryCode}${btnPrev}`}>
                  <img alt="arrow back" src={Chevron02} />
                </Link></li>

              <li className="list-inline-item"><span>Znaleziono: {count} obiektów, </span></li>
              <li className="list-inline-item"><span>Strona: {`${(this.props.currPage + 1)}/${lastPage}`}</span></li>

              <li className={`${isLastPage ? "disabled" : ""} next list-inline-item`}>
                <Link to={`/${mode}/${this.props.summaryCode}${!isLastPage ? btnNext : btnNextNext}`}>
                  <img alt="arrow forward" src={Chevron02} />
                </Link></li>

              <li className={`${isLastPage ? "disabled" : ""} next list-inline-item`}>
                <Link to={`/${mode}/${this.props.summaryCode}${btnNextNext}`}>
                  <img alt="arrows forward" src={Chevron01} />
                </Link></li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
};

PagerChildren.propTypes = {
  children: PropTypes.object, //HOC
  pathname: PropTypes.string.isRequired,
  currPage: PropTypes.number.isRequired,
  query: PropTypes.object.isRequired,
  summaryCode: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired
};

const mapStateToProps = (state) => {
  return { children: state.abilities };
};

export default connect(mapStateToProps)(PagerChildren);