import React from 'react';
import { Spinner } from 'reactstrap';

export const renderSpinner = () => {
  return (
    <div>
      <div className="spinnerBG"></div>
      <Spinner className="spinner" animation="border" variant="primary" color="primary" />
    </div>
  );
};