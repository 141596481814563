import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MathJax from 'react-mathjax-preview';

import { fetchChildren } from '../actions';
import { fetchAbilities } from '../actions';
import { loadingStarted } from '../actions';
import PagerChildren from './PagerChildren.jsx';
import { setFirstPage } from '../utils/setFirstPage';
import { detailsStrings } from '../utils/detailsStrings';
import { renderSpinner } from '../components/RenderSpinner';

class AbilityChildren extends React.Component {

  componentDidMount() {
    this.props.loadingStarted();
    this.props.fetchChildren(this.props.pathName, this.props.summaryCode, this.props.limit, this.props.offset);

    if (!this.props.abilities.getIn([ "facets" ])) {
      this.props.loadingStarted();
      this.props.fetchAbilities(this.props.pathName, this.props.queryString, this.props.limit, this.props.offset);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pathName !== this.props.pathName || prevProps.summaryCode !== this.props.summaryCode) {
      this.props.loadingStarted();
      this.props.fetchChildren(this.props.pathName, this.props.summaryCode, this.props.limit, this.props.offset);
    }
    if (prevProps.search.page !== this.props.search.page) {
      this.props.loadingStarted();
      this.props.fetchChildren(this.props.pathName, this.props.summaryCode, this.props.limit, this.props.offset);
    }
  }

  renderList() {
    const childrenArr = this.props.abilities.getIn([ "currentChildPage", "ids" ]);
    const detailsChild = this.props.abilities.getIn([ "children" ]);
    let pathname = this.props.pathName ? this.props.pathName : '/ability/';

    let mode = null;
    if (pathname.includes('/ability/'))
      mode = "ability";
    if (pathname.includes('/objective/'))
      mode = "objective";

    return childrenArr && childrenArr.map((child) => {
      const childName = detailsChild.getIn([ child, "name" ]);
      return (
        <tr className="linkable" key={child}>
          <td className="col-code">
            <Link to={`/${mode}/${child}/${setFirstPage(this.props.search)}`}>{detailsChild.getIn([ child, "summary_code" ])}</Link>
          </td>
          <td className="col-desc">
            <Link to={`/${mode}/${child}/${setFirstPage(this.props.search)}`}>
              <MathJax math={childName} />
            </Link>
            <br />
            <small>
              <i>Poziom: </i>
              {detailsStrings("stages", detailsChild, child)} <br />
              <i>Szkoła: </i>
              {detailsStrings("schools", detailsChild, child)} <br />
              <i>Zakres: </i>
              {detailsStrings("scope", detailsChild, child)} <br />
              <i>Wersje: </i>
              {detailsStrings("versions", detailsChild, child)} <br />
            </small>
          </td>
        </tr>
      );
    });
  };

  render() {
    let childrenCount = this.props.abilities.getIn([ "currentChildPage", "count" ]);
    let pathname = this.props.pathName ? this.props.pathName : "/ability/";
    let loadingState = this.props.abilities.getIn([ "loading" ]);
    if (childrenCount > 0) {
      return (
        <div className="spinnerParent">
          {pathname.includes('/ability/') ? <h1>Umiejętności składowe</h1> : (pathname.includes('/objective/') ? <h1>Składowe cele kształcenia</h1> : null)}
          {loadingState && renderSpinner()}
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="col-code">Odnośnik</th>
                <th className="col-desc">Nazwa</th>
              </tr>
            </thead>
            <tbody>
              {this.renderList()}
            </tbody>
          </table>
          <PagerChildren 
            pathname={pathname} 
            summaryCode={this.props.summaryCode} 
            currPage={this.props.currPage} 
            limit={this.props.limit} 
            query={setFirstPage(this.props.search)} 
          />
        </div>
      );
    }
    else return null;
  }
}

AbilityChildren.propTypes = {
  fetchAbilities: PropTypes.func, //HOC
  fetchChildren: PropTypes.func, //HOC
  loadingStarted: PropTypes.func, //HOC
  abilities: PropTypes.func, //HOC
  pathName: PropTypes.string,
  summaryCode: PropTypes.string,
  limit: PropTypes.number,
  offset: PropTypes.number,
  search: PropTypes.object,
  currPage: PropTypes.number,
  queryString: PropTypes.string,
};

const mapStateToProps = (state) => {
  return { abilities: state.abilities };
};

export default connect(mapStateToProps, { fetchChildren, fetchAbilities, loadingStarted })(AbilityChildren);
