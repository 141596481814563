import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { addToQuery } from '../utils/queryUtils.js';

class FacetsList extends React.Component {

  state = {
    activeTab: '1'
  };

  renderList(typeList = "stage") {
    let pageParam = this.props.queryString;
    const facetsArr = (this.props.abilities && this.props.abilities.getIn([ "facets", typeList ])) || [];

    return (
      facetsArr && facetsArr.map((stageX) => {
        return (
          <ListGroupItem className="list-group-facet" key={stageX.code}>
            <Link className="list-group-item" to={`${addToQuery(pageParam, typeList, stageX.code)}`}>
              {stageX.name}
              <Badge pill>{stageX.count}</Badge>
            </Link>
            
          </ListGroupItem>
        );
      })
    );
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              Poziom
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              Szkoła
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); }}
            >
              Kategoria
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '4' })}
              onClick={() => { this.toggle('4'); }}
            >
              Przedmiot
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '5' })}
              onClick={() => { this.toggle('5'); }}
            >
              Zawód
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '6' })}
              onClick={() => { this.toggle('6'); }}
            >
              Zakres
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '7' })}
              onClick={() => { this.toggle('7'); }}
            >
              Wersja
            </NavLink>
          </NavItem>
        </Nav>
        <div className="tab list-group facet-list">
          <TabContent activeTab={this.state.activeTab} className="tab-content">
            <TabPane tabId="1">
              <Row>
                <Col>
                  <ListGroup>
                    {this.renderList()}
                  </ListGroup>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col>
                  <ListGroup>
                    {this.renderList("school")}
                  </ListGroup>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col>
                  <ListGroup>
                    {this.renderList("category")}
                  </ListGroup>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col>
                  <ListGroup>
                    {this.renderList("subject")}
                  </ListGroup>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="5">
              <Row>
                <Col>
                  <ListGroup>
                    {this.renderList("profession")}
                  </ListGroup>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="6">
              <Row>
                <Col>
                  <ListGroup>
                    {this.renderList("scope")}
                  </ListGroup>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="7">
              <Row>
                <Col>
                  <ListGroup>
                    {this.renderList("version")}
                  </ListGroup>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  };
}

FacetsList.propTypes = {
  abilities: PropTypes.object, //HOC
  queryString: PropTypes.string,
};

const mapStateToProps = (state) => {
  return { abilities: state.abilities };
};

export default connect(mapStateToProps)(FacetsList);