import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink } from 'reactstrap';

import { __env } from '../../envloader/index';
import HeaderPodstawaProgramowa from '@assets/svg/header_podstawa_programowa.svg';
import LogoBialeHeader from '@assets/svg/logo_biale_header.svg';

class Topbar extends Component {

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <div className="new-header" >

        <div className="header-left-icon">
          <Link to="/">
            <img src={HeaderPodstawaProgramowa} height="75px" alt="logo Podstawa programowa" />
            Podstawa programowa
          </Link>
        </div>

        <div className="pionier-logo">
          <a href={__env.CLASSROOM_URL}><img src={LogoBialeHeader} height="75px" alt="logo Pionier" /></a>
        </div>

        <div className="hamburger-menu">
          <Navbar color="faded" dark>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse isOpen={!this.state.collapsed} navbar>
              <Nav navbar>
                <NavItem>
                  <NavLink tag={Link} to="/about/">O Usłudze</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/legal_acts/">Akty prawne</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href={__env.API_ROOT_URL + "/schema/v1/"}>API</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
};

export default Topbar;
