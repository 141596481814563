export const detailsStrings = (type, detailsAb, ability = null) => {
  let sum = "";
  let abilityMap;
  if (detailsAb) {
    if (ability) abilityMap = detailsAb.getIn([ ability, type ]);
    if (!ability) abilityMap = detailsAb.getIn([ type ]);
    if (type !== "scope" && type !== "subject") {
      sum = "";
      abilityMap && abilityMap.forEach((abi) => {
        for (var [ key, value ] of abi) {
          if (key === "code") sum += `[${value}] `;
          if (key !== "code") sum += value + ", ";
        };
      });
    }
    else {
      sum = "";
      if (abilityMap) {
        for (var [ k, v ] of abilityMap) {
          if (k === "code") sum += `[${v}] `;
          if (k !== "code") sum += v + ", ";
        };
      }
    };
  };
  return sum;
};