import React from 'react';
import { Link } from 'react-router-dom';

import PCSSLogo from '@assets/img/PCSS_logo_niebieskie_.png';
import LibrusLogo from '@assets/img/LIBRUS_logo.png';

const Footer = () => {
  return (
    <footer>
      <div className="space-between">

        <div className="uspp-logo-footer">
          <Link id="header-link" to="/">USPP</Link>
          <span id="header-text">Usługa Słownika Podstawy Programowej</span>
        </div>

        <div>
          <a href="http://www.man.poznan.pl/">
            <img src={PCSSLogo} alt="Logo PCSS" />
          </a>
          <p className="pcss-date">© 2013-2019</p>
        </div>

        <div className="librus-logo">
          <a href="https://www.librus.pl/">
            <img src={LibrusLogo} alt="Logo LIBRUS" />
          </a>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
