import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';

import AbilityDetails from './AbilityDetails.jsx';
import AbilityChildren from './AbilityChildren.jsx';
import ChangeRegister from './ChangeRegister.jsx';

class AbilityDetailsPage extends Component {

  render() {
    const summaryCodeVar = this.props.match.params.sumCode;
    let pageParam = queryString.parse(this.props.location.search);
    let pathname = this.props.location.pathname ? this.props.location.pathname : "/ability/";
    let limitQuery = 3;

    if (!pageParam.page || pageParam.page <= 0) { pageParam.page = 1; };
    return (
      <div>
        <AbilityDetails pathName={pathname} summaryCode={summaryCodeVar} />

        <AbilityChildren pathName={pathname} summaryCode={summaryCodeVar}
          limit={limitQuery} offset={(pageParam.page - 1) * limitQuery}
          currPage={pageParam.page - 1} search={pageParam}
        />

        {pathname.includes('/ability/') ? <h1>Rejestr zmian bieżącej umiejętności</h1> : null}
        {pathname.includes('/ability/') ? <ChangeRegister pathName={pathname} summaryCode={summaryCodeVar} /> : null}

      </div>
    );
  }
}

AbilityDetailsPage.propTypes = {
  match: PropTypes.object, //HOC
  location: PropTypes.object, //HOC
};

const mapStateToProps = (state) => {
  return { summary: state.abilities };
};

export default connect(mapStateToProps)(AbilityDetailsPage);