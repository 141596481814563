import React from 'react';
import { Link } from 'react-router-dom';

class Header extends React.Component {
  render() {
    let activeUrl = window.location.href;
    return (
      <nav className="navbar navbar-expand-sm">
        <div className="navbar-collapse justify-content-end">
          <ul id="pages-menu" className="navbar-nav">
            <li className="nav-item">
              <Link to="/about/" className="nav-link" title="Podstawa programowa | o usłudze">
                O Usłudze
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/legal_acts/" className="nav-link" title="Rozporządzenie Ministra Edukacji Narodowej w sprawie podstawy programowej">
                Akty prawne
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about/" className="nav-link">
                Dołącz do inicjatywy
              </Link>
            </li>
            <li className="nav-item"><a href="https://uspp.pl/api/schema/v1/" className="nav-link">API</a></li>
          </ul>
          <ul id="category-menu" className="nav nav-pills nav-pills-rad">
            <li 
              className={!activeUrl.includes("objective") ? 
                "navbar-brand active" : 
                "navbar-brand"
              }>
              <Link to="/" className="btn-header">
                Umiejętności
              </Link>
            </li>
            <li 
              className={activeUrl.includes("objective") ? 
                "navbar-brand active" : 
                "navbar-brand"
              }>
              <Link to="/objectives/" className="btn-header">
                Cele kształcenia
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
};

export default Header;