/*"""
.. describe:: common/utils/logger

 logger usage:

 import ``getLogger`` function and create new logger by passing options with logger name:
 ``const logger = getLogger({ loggerName: 'some name, maybe module name' })``

 log some message:
 ``logger.<method>(message, metadata)``
 where method is one of popular browser console methods: ``info``, ``warn``, ``error``, etc
 */
import winston from 'winston';
import Transport from 'winston-transport';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import _ from 'lodash';
 
import { __env } from '../../envloader/index';
 
const stringToColour = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (let i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};
 
const pad = (num) => ('0' + num).slice(-2);
 
const formatTime = (date) => {
  return `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}.${date.getMilliseconds()}`;
};
 
 
class BrowserConsoleLogger extends Transport {
 
  constructor(options = {}) {
    super(options);
    this._logs = [];
 
    this.name = 'browserConsoleLogger';
 
    this.level = options.level || 'info';
 
    this.loggerName = options.loggerName || '';
 
    this.style = this.loggerName !== '' ? `color: ${stringToColour(this.loggerName)}; font-weight: bold` : '';
  }
 
   log = (info, callback) => {
     setImmediate(() => {
       this.emit('logged', info);
     });
     this._logs.push({ level: info.level, message: info.message, meta: info.meta, date: Date.now() });
     // eslint-disable-next-line
     console[info.level](`%c ${this.loggerName} @ ${formatTime(new Date())}:`, this.style, info.message);
     callback();
   };
 
   query = (options, callback) => {
     let result = this._logs.slice();
     if (options.message) {
       result = _.filter(result, function (r) {
         return r.message === options.message;
       });
     }
     if (options.from) {
       result = _.filter(result, function (r) {
         return r.date >= options.from;
       });
     }
     callback(null, result);
   }
}
 
class SentryLogger extends Transport {
 
  constructor(options = {}) {
    super(options);
    this.name = 'SentryLogger';
 
    this.level = 'error';
 
    this.loggerName = options.loggerName || 'javascript';
 
    this.silent = !__env.SENTRY_DSN;
  }
 
   log = (info, callback) => {
     setImmediate(() => {
       this.emit('logged', info);
     });
     const opts = { logger: this.loggerName };
     Sentry.withScope(scope => {
       scope.setLevel(info.level);
       scope.setExtra('options', opts);
       scope.setExtra('meta', info.meta);
       if (_.isString(info.message) && _.isObject(info.meta)) {
         scope.setExtra('message', info.message);
         Sentry.captureException(info.meta);
       }
       else if (_.isString(info.message) && !_.isObject(info.meta)) {
         Sentry.captureMessage(info.message);
       }
       else if (_.isObject(info.message) && _.isObject(info.meta)) {
         scope.setExtra('meta', info.meta);
         Sentry.captureException(info.message);
       }
       else if (_.isObject(info.message)) {
         Sentry.captureException(info.message);
       }
       else {
         Sentry.captureMessage(info.message);
       }
     });
     callback();
   };
}
 
 
export const getLogger = (opts) => {
  return new winston.createLogger({
    transports: [
      new BrowserConsoleLogger({
        handleExceptions: true,
        ...opts
      }),
      new SentryLogger({
        handleExceptions: true,
        ...opts
      })
    ],
    exitOnError: false
  });
};
 
const _initializeSentry = () => {
  Sentry.init({
    dsn: __env.SENTRY_DSN,
    maxValueLength: 10000,
    integrations(integrations) { return [ new Integrations.ExtraErrorData({ depth: 5 }), ...integrations ]; },
    beforeSend(event) {
      if (!event.extra && event.contexts && event.contexts.Error) {
        event.extra = { meta: { response: event.contexts.Error.response } };
      }
      return event;
    }
  });
  Sentry.configureScope((scope) => {
    scope.setTag("environment", __env.SENTRY_ENVIRONMENT);
  });
};
 
if (__env.SENTRY_DSN) {
  _initializeSentry();
}
 
export const initializeSentry = () => {
  // dummy function only for import and fake call in root index.js to init Sentry as soon as possible
};
 